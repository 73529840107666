:root {
  color-scheme: only light;
}
.rounded img {
  border-radius: 50%;
}
.MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.5) !important;
}

.dashboard-calendar .react-calendar {
  width: 100% !important;
  border: none;
  background-color: rgba(245, 245, 245, 0.4);
  border-radius: 10px;
  padding: 5px;
}
.dashboard-calendar .react-calendar__navigation__label__labelText {
  font-size: 13px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}
.dashboard-calendar .react-calendar__month-view__weekdays__weekday {
  font-size: 14px;
  font-weight: 400;
  color: rgba(129, 129, 129, 1);
}
.dashboard-calendar .react-calendar__month-view__weekdays__weekday abbr {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}
.dashboard-calendar
  .react-calendar__month-view__days__day--weekend:not(.react-calendar__tile--active) {
  color: rgba(65, 177, 26, 1);
}
.dashboard-calendar .react-calendar__tile--now {
  background: inherit;
}
.dashboard-calendar .react-calendar__tile--active,
.dashboard-calendar .react-calendar__tile--active:hover,
.dashboard-calendar .react-calendar__tile--active:active,
.dashboard-calendar .react-calendar__tile--active:enabled:hover,
.dashboard-calendar .react-calendar__tile--active:enabled:focus {
  background: rgba(65, 177, 26, 1);
  border-radius: 50%;
  /* flex: 0 0 8% !important;
  margin-inline-end: auto;
  margin: 0 3% !important; */
}
